import { useState, useEffect } from "react";

export type TimeRemaining = {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
};

function useCountdown(targetDate: string, clientTime?: number, startOfDay?: boolean): TimeRemaining {
  const [timeRemaining, setTimeRemaining] = useState<TimeRemaining>(calculateTimeRemaining(clientTime));
  calculateTimeRemaining();
  function calculateTimeRemaining(initialTime?: number): TimeRemaining {
      let target = new Date(targetDate);
      if (startOfDay) {
          target.setHours(0, 0, 0, 0);
        }
    const diff =
        target.getTime() - (initialTime || new Date().getTime());
        
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((diff / 1000 / 60) % 60);
      const seconds = Math.floor((diff / 1000) % 60);

      if (diff < 0) {
        return {
          days: "00",
          hours: "00",
          minutes: "00",
          seconds: "00",
        };
      }
      return {
        days: days < 10 ? "0" + days : days.toString(),
        hours: hours < 10 ? "0" + hours : hours.toString(),
        minutes: minutes < 10 ? "0" + minutes : minutes.toString(),
        seconds: seconds < 10 ? "0" + seconds : seconds.toString(),
      };

  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return timeRemaining;
}

export default useCountdown;
